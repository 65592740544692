export const DEFAULT_DECIMALS_NUM = 5;

export function formatValue(
  value?: string | number,
  numberOfDecimal = 5,
): string {
  if (value === undefined || value === null || value === '') {
    return 'N/A';
  }

  const numericValue = parseFloat(value.toString());
  if (isNaN(numericValue)) {
    return 'N/A';
  }

  return Number.isInteger(numericValue)
    ? numericValue.toString()
    : numericValue.toFixed(numberOfDecimal).replace(/\.?0+$/, '');
}
